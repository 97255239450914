var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("储备客户")]), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "background-color": _vm.$vuetify.theme.dark ? '' : 'grey lighten-4',
      "label": "关键字搜索（按回车）",
      "prepend-inner-icon": "search",
      "solo": "",
      "flat": "",
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchItem();
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  }), _c('v-btn', {
    staticClass: "mx-4",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.openFilter();
      }
    }
  }, [_c('v-icon', [_vm._v("filter_list")])], 1), _c('v-divider', {
    staticClass: "mx-4",
    attrs: {
      "inset": "",
      "vertical": ""
    }
  }), _c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "color": "primary",
      "depressed": ""
    },
    on: {
      "click": function click($event) {
        return _vm.createItem();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v(" 新增客户 ")], 1)], 1), _c('v-card-subtitle', [_vm._v(" 储备客户是鑫泰能源对未签署正式合同前的客户备案模块，此模块将对备案客户进行资料维护、附件及备注标记. ")])], 1), _vm.filingList.length ? _c('v-row', {
    staticClass: "fill-height overflow-y-auto ma-2"
  }, [_vm._l(_vm.filingList, function (item) {
    var _item$creator, _item$creator2, _item$creator3, _item$creator3$person;
    return _c('v-col', {
      key: item._id,
      attrs: {
        "lg": "4",
        "md": "4",
        "sm": "6",
        "cols": "12"
      }
    }, [_c('v-card', {
      attrs: {
        "outlined": "",
        "hover": "",
        "ripple": false,
        "color": item.isBlock ? 'red lighten-4' : ''
      }
    }, [_c('v-card-title', [_c('span', {
      staticClass: "d-inline-block text-truncate"
    }, [_vm._v(_vm._s(item.name))])]), _c('v-card-text', [_c('v-list', {
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item', {
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("contact_phone")])], 1), _c('v-list-item-subtitle', [_vm._v(_vm._s(item.contact_name) + " - " + _vm._s(item.contact_phone))])], 1), _c('v-list-item', {
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("room")])], 1), _c('v-list-item-subtitle', [_vm._v(_vm._s(item.city) + " - " + _vm._s(item.address))])], 1)], 1)], 1), _c('v-divider', {
      staticClass: "mx-4"
    }), _c('v-card-actions', [(_item$creator = item.creator) !== null && _item$creator !== void 0 && _item$creator._id ? _c('v-subheader', [_c('account-dialogs', {
      attrs: {
        "account": (_item$creator2 = item.creator) === null || _item$creator2 === void 0 ? void 0 : _item$creator2._id
      }
    }, [_vm._v(_vm._s((_item$creator3 = item.creator) === null || _item$creator3 === void 0 ? void 0 : (_item$creator3$person = _item$creator3.personal) === null || _item$creator3$person === void 0 ? void 0 : _item$creator3$person.name))]), _vm._v(" 于 " + _vm._s(_vm._f("formatDate")(item.createTime)) + " 创建 ")], 1) : _vm._e(), _c('v-spacer'), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.openItem(item._id);
        }
      }
    }, [_c('v-icon', [_vm._v("more_horiz")])], 1)], 1)], 1)], 1);
  }), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [this.query.page * this.query.limit < _vm.filingCount ? _c('v-progress-circular', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.infiniteScrolling,
        options: {
          threshold: [1.0]
        }
      },
      expression: "{\n        handler: infiniteScrolling,\n        options: {\n            threshold: [1.0]\n        }\n      }"
    }],
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "width": "2"
    }
  }) : _c('span', {
    staticClass: "title font-weight-light"
  }, [_vm._v("已展示" + _vm._s(_vm.filingCount) + "家企业信息，没有更多数据了。")])], 1)], 2) : _c('v-row', [_c('span', {
    staticClass: "bg"
  })]), _c('v-fab-transition', [_c('v-btn', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }, {
      name: "show",
      rawName: "v-show",
      value: _vm.fab,
      expression: "fab"
    }],
    staticClass: "transition-swing",
    staticStyle: {
      "z-index": "6"
    },
    attrs: {
      "color": "secondary",
      "fab": "",
      "dark": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "large": ""
    },
    on: {
      "click": _vm.toTop
    }
  }, [_c('v-icon', [_vm._v("keyboard_arrow_up")])], 1)], 1), _c('v-navigation-drawer', {
    staticStyle: {
      "z-index": "999"
    },
    attrs: {
      "app": "",
      "temporary": "",
      "bottom": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.clearFilter();
            }
          }
        }, [_vm._v("清空")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.setFilter();
            }
          }
        }, [_vm._v("确认")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("企业类型")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    model: {
      value: _vm.query.corp_type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "corp_type", $$v);
      },
      expression: "query.corp_type"
    }
  }, _vm._l(_vm.corpTypedef, function (item) {
    return _c('v-chip', {
      key: item.code,
      attrs: {
        "value": item.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("企业状态")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    model: {
      value: _vm.query.isBlock,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isBlock", $$v);
      },
      expression: "query.isBlock"
    }
  }, [_c('v-chip', {
    attrs: {
      "value": false,
      "filter": "",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v("正常运营")]), _c('v-chip', {
    attrs: {
      "value": true,
      "filter": "",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v("暂停运营")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }