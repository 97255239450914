<template>
  <v-container>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>储备客户</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
          @keydown.enter="searchItem()"
          label="关键字搜索（按回车）"
          prepend-inner-icon="search"
          solo
          flat
          dense
          hide-details
          clearable
        ></v-text-field>
        <v-btn icon class="mx-4" @click.stop="openFilter()">
          <v-icon>filter_list</v-icon>
        </v-btn>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="primary" depressed class="mx-2" @click="createItem()">
          <v-icon left>add</v-icon>
          新增客户
        </v-btn>
      </v-toolbar>
      <v-card-subtitle>
        储备客户是鑫泰能源对未签署正式合同前的客户备案模块，此模块将对备案客户进行资料维护、附件及备注标记.
      </v-card-subtitle>
    </v-card>
    <v-row class="fill-height overflow-y-auto ma-2" v-if="filingList.length">
      <v-col lg="4" md="4" sm="6" cols="12" v-for="item in filingList" :key="item._id">
        <v-card outlined hover :ripple="false" :color="item.isBlock ? 'red lighten-4' : ''">
          <v-card-title><span class="d-inline-block text-truncate">{{item.name}}</span></v-card-title>
          <v-card-text>
            <v-list dense>
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon small>contact_phone</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>{{item.contact_name}} - {{item.contact_phone}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon small>room</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>{{item.city}} - {{item.address}}</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-actions>
            <v-subheader v-if="item.creator?._id">
              <account-dialogs :account="item.creator?._id">{{item.creator?.personal?.name}}</account-dialogs>
              于 {{item.createTime | formatDate}} 创建
            </v-subheader>
            <v-spacer></v-spacer>
            <v-btn icon @click="openItem(item._id)"><v-icon>more_horiz</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-progress-circular
          v-if="this.query.page * this.query.limit < filingCount"
          indeterminate
          color="primary"
          width="2"
          v-intersect="{
          handler: infiniteScrolling,
          options: {
              threshold: [1.0]
          }
        }"
        ></v-progress-circular>
        <span v-else class="title font-weight-light">已展示{{filingCount}}家企业信息，没有更多数据了。</span>
      </v-col>
    </v-row>
    <v-row v-else>
      <span class="bg"></span>
    </v-row>
    <v-fab-transition>
      <v-btn
        class="transition-swing"
        v-scroll="onScroll"
        v-show="fab"
        color="secondary"
        fab
        dark
        fixed
        bottom
        right
        large
        @click="toTop"
        style="z-index: 6"
      >
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-navigation-drawer v-model="filterDrawer" app temporary bottom right style="z-index: 999;">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="subtitle-2 mt-2">企业类型</h2>
        <v-chip-group v-model="query.corp_type" column multiple>
          <v-chip v-for="item in corpTypedef" :key="item.code" :value="item.code" filter label outlined>{{item.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">企业状态</h2>
        <v-chip-group v-model="query.isBlock" column multiple>
          <v-chip :value="false" filter label outlined>正常运营</v-chip>
          <v-chip :value="true" filter label outlined>暂停运营</v-chip>
        </v-chip-group>
      </v-card-text>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="clearFilter()">清空</v-btn>
          <v-btn color="secondary" text @click="setFilter()">确认</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
import store from "@/store";
import { mapGetters } from "vuex";
import Util from '@/common/util';
import {
  FETCH_FILING_LIST,
} from "@/store/actions.type";
export default {
  data: () => ({
    query: {
      key: '',
      corp_type: [],
      isBlock: [],
      page: 1,
      limit: 10,
    },
    defaultQuery: {
      key: '',
      corp_type: [],
      isBlock: [],
      page: 1,
      limit: 10,
    },
    filingList: [],
    filterDrawer: null,
    fab: false,
  }),
  computed: {
    ...mapGetters(["currentUser", "filingLoading", "filingCount"]),
    corpTypedef () {
      return Util.categories('corpType')
    },
  },
  mounted() {
    if (Object.keys(this.$route.query).length > 0) this.query = Object.assign(this.query, this.$route.query)
    this.getList()
  },
  created() {},
  methods: {
    getList() {
      let strQuery = '';
      if (this.query.key) strQuery += '&key='+ this.query.key
      if (this.query.isBlock.length && this.query.isBlock.length <= 1) strQuery += '&isBlock='+ this.query.isBlock
      if (this.query.corp_type.length) strQuery += '&corp_type='+ this.query.corp_type
      if (this.query.page) strQuery += '&page='+ this.query.page
      if (this.query.limit) strQuery += '&limit='+ this.query.limit
      store.dispatch(FETCH_FILING_LIST, strQuery)
        .then((data) => {
          this.filingList.push(...data.docs)
        })
    },
    searchItem() {
      this.query.page = 1;
      this.query.limit = 20;
      this.filingList = [];
      this.getList();
    },
    openFilter() {
      this.filterDrawer = !this.filterDrawer
    },
    clearFilter() {
      this.query = Object.assign({}, this.defaultQuery)
      this.filingList = []
      this.getList()
      this.filterDrawer = !this.filterDrawer
    },
    setFilter() {
      this.query.page = 1;
      this.query.limit = 20;
      this.filingList = [];
      this.getList();
      this.filterDrawer = !this.filterDrawer
    },
    createItem() {
      this.$router.push({path:'/distributor/filing/edit/0'})
    },
    openItem(id) {
      this.$router.push({path:'/distributor/filing/edit/'+ id})
    },
    infiniteScrolling() {
      this.query.page++;
      this.getList();
    },
    onScroll () {
      if (typeof window === 'undefined') return
      const top = (
        window.pageYOffset ||
        document.documentElement.offsetTop ||
        0
      )
      this.fab = top > 300
    },
    toTop () {
      if (this.$route.hash) {
        this.$router.push({ hash: '' })
      }
      this.$vuetify.goTo(0)
    },
  }
};
</script>
<style scoped>
.hideText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0;
  background: url( '/static/empty_data.png') no-repeat center center;
  background-size: contain;
  transform: scale(0.15);
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
